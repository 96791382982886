import React from 'react';
import { ShippingUnitDTO } from '../../services/shipping-service/shipping.service';
import { CustomerService } from '../../services/customer-service/customer.service';
import { useIntParam } from '../../hooks/useParam';
import DataTable, { TDataTableColumn } from '../../shared/components/data-table/DataTable';
import { useTranslation } from 'react-i18next';
import { isToday } from '../../shared/helper/date';
import { ShippingUnitState } from '../../shared/backend';
import Lock from '@mui/icons-material/Lock';
import { LockOpen } from '@mui/icons-material';
import { Stack, CircularProgress, Typography, Box, TablePagination } from '@mui/material';

type Props = {
  units: ShippingUnitDTO[];
  isLoading: boolean;
  isValidating: boolean;
};

const ShippingUnitTable: React.FC<Props> = ({ units, isLoading, isValidating }) => {
  const { t } = useTranslation();

  const [page, setPage] = useIntParam('page', 0);
  const [rowsPerPage, setRowsPerPage] = useIntParam('pageSize', 10);

  const customers = CustomerService.useCustomers();

  const columns: TDataTableColumn<ShippingUnitDTO>[] = [
    {
      title: t('Unit id'),
      field: 'shippingUnitId',
    },
    {
      title: t('Unit barcode'),
      field: 'barcode',
    },
    {
      title: t('Customer'),
      render: ({ customerId }) =>
        customers.data.find((customer) => customer.customerId === customerId)?.company ?? customerId,
    },
    {
      title: t('Address'),
      render: ({ returnWarehouseAddress }) => (
        <span>
          {returnWarehouseAddress.street}
          <br />
          {returnWarehouseAddress.city}
        </span>
      ),
    },
    {
      title: t('Shipments'),
      field: 'size',
    },
    {
      title: t('Weight'),
      render: ({ weight }) => `${(weight / 1000).toFixed(2)} kg`,
    },
    {
      title: t('State'),
      render: ({ state }) => (state === ShippingUnitState.CLOSED ? <Lock /> : <LockOpen />),
    },
    {
      title: t('Created at'),
      render: ({ createdAt }) =>
        isToday(new Date(createdAt)) ? new Date(createdAt).toLocaleTimeString() : new Date(createdAt).toLocaleString(),
    },
    {
      title: t('Closed at'),
      render: ({ closedAt }) =>
        !closedAt
          ? ''
          : isToday(new Date(closedAt))
            ? new Date(closedAt).toLocaleTimeString()
            : new Date(closedAt).toLocaleString(),
    },
  ];

  return (
    <>
      <DataTable
        page={page}
        columns={columns}
        rows={units}
        isLoading={isLoading}
      />

      <Stack
        direction="row"
        alignItems="center"
      >
        {isValidating && (
          <>
            <CircularProgress
              size="1.2em"
              sx={{ marginX: 1, color: 'text.disabled' }}
            />
            <Typography
              variant="body2"
              color="text.disabled"
            >
              <em>{t('Looking for new data...')}</em>
            </Typography>
          </>
        )}

        <Box flexGrow={1}></Box>

        <TablePagination
          component="div"
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20, 30]}
          onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
          page={page}
          count={units.length < rowsPerPage ? page * rowsPerPage + units.length : -1}
          onPageChange={(ev, page) => setPage(page)}
          nextIconButtonProps={{
            disabled: units.length < rowsPerPage,
          }}
        />
      </Stack>
    </>
  );
};

export default ShippingUnitTable;
